@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import "swiper/scss";
@import "swiper/scss/navigation";
@import '@ionic/angular/css/ionic-swiper';
@import 'swiper/scss/pagination';

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* This sets a different background and item background in light mode on ios */
.ios body {
  --ion-background-color: #111;
  --ion-toolbar-background: #111;
  --ion-item-background: #111;
}

/* This sets a different background and item background in light mode on md */
.md body {
  --ion-background-color: #121212;
  --ion-toolbar-background:#121212;
  --ion-item-background: #121212;
}

/* This is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

.sc-ion-buttons-ios-h {
  width: fit-content!important;
}
app-chat {
  z-index: 1!important;
}
